<template>
  <div class="hk">
    <!-- <div class="header_pic">
      <img src="~@/assets/images/hk/header_img.png" alt="" />
    </div> -->
    <div class="idCard">
      <div class="idCard_left">
        <div>
          <p>卡号：</p>
          <p>{{ info.old_iccid }}</p>
        </div>
        <div>
          <p>余额：</p>
          <p>{{ info.c_balance }}</p>
        </div>
        <div>
          <p>到期时间：</p>
          <p>{{ info.c_extime }}</p>
        </div>
      </div>
      <div class="idCard_right">
        <img src="~@/assets/images/hk/header_img.png" alt="" />
      </div>
    </div>
    <div class="card2">❗{{ shopInfo.package }}</div>
    <div class="main_box">
      <div class="main_tip" style="margin-top: 0">
        <div>
          新卡资费：<span style="color: #fe3b30; font-weight: 500">{{
            shopInfo.cost
          }}</span>
        </div>
        <div>
          流量额度：<span
            style="color: #fe3b30; font-weight: 500; white-space: nowrap"
            >{{ shopInfo.flow }}（不限速、不限App、通用流量）</span
          >
        </div>
        <div>月底清零：不清零</div>
        <div>请您确认以下信息是否准确无误</div>
      </div>
      <div>
        <div class="popBox">
          <div class="inp">
            <van-cell-group>
              <van-field
                v-model="form.name"
                label="姓名"
                placeholder="请输入真实姓名"
              />
            </van-cell-group>
          </div>
          <div class="inp">
            <van-cell-group>
              <van-field
                v-model="form.phone"
                label="电话"
                type="tel"
                maxlength="11"
                placeholder="请输入联系电话"
              />
            </van-cell-group>
          </div>
          <div class="inp">
            <van-cell-group>
              <van-field
                v-if="form.showCode"
                v-model="form.cardId"
                label="验证码"
                type="tel"
                maxlength="6"
                placeholder="请填写手机验证码"
              >
                <template #button>
                  <!-- <van-button size="small" type="primary">发送验证码</van-button> -->
                  <div
                    style="
                      color: #fff;
                      padding: 0 10px;
                      background: #1e6fff;
                      border-radius: 60px;
                    "
                    @click="getCode()"
                    v-if="!disabled"
                  >
                    {{ codeText }}
                  </div>
                  <div style="color: #f1462e; padding: 0 20px" v-if="disabled">
                    {{ codeText }}
                  </div>
                </template>
              </van-field>
            </van-cell-group>
          </div>
          <div class="area">
            <div class="area-a">地址</div>
            <div style="width: 100%; height: 44px; line-height: 44px">
              <div @click="picker = true">
                {{ form.province + " " + form.city + " " + form.area }}
              </div>
            </div>
          </div>

          <div class="inp">
            <van-cell-group>
              <van-field
                v-model="form.addressInfo"
                placeholder="请输入详细地址"
              />
            </van-cell-group>
          </div>

          <div class="payType">
            <div class="item" @click="selectPay(1)">
              <div class="left">
                <img src="~@/assets/images/bigFlow/wxpay.png" alt="" />
                <span>微信支付</span>
              </div>
              <div class="right">
                <img
                  src="~@/assets/images/bigFlow/noActive.png"
                  alt=""
                  v-if="payType == 2"
                />
                <img
                  src="~@/assets/images/bigFlow/active.png"
                  alt=""
                  v-if="payType == 1"
                />
              </div>
            </div>
            <div class="item" @click="selectPay(2)" v-if="!wx">
              <div class="left">
                <img src="~@/assets/images/bigFlow/alipay.png" alt="" />
                <span>支付宝支付</span>
              </div>
              <div class="right">
                <img
                  src="~@/assets/images/bigFlow/noActive.png"
                  alt=""
                  v-if="payType == 1"
                />
                <img
                  src="~@/assets/images/bigFlow/active.png"
                  alt=""
                  v-if="payType == 2"
                />
              </div>
            </div>
          </div>
          <!-- <div class="footbtn">
            <van-button
              :disabled="flag"
              type="primary"
              round
              size="large"
              color="#1E6FFF"
              @click="onbuyCard()"
              >确认地址</van-button
            >
          </div> -->
        </div>
      </div>

      <van-popup v-model="picker" position="bottom" :style="{ height: '50%' }">
        <van-area
          @confirm="confirm"
          @cancel="cancel()"
          title="选择地址"
          :area-list="areaList"
          :value="areainfo"
        />
      </van-popup>
    </div>
    <div class="card1">
      <div>注意事项：</div>
      <p>1、如有任何疑问，请及时联系我们的客服人员。</p>
      <p>2、感谢您的配合和理解，我们将竭诚为您提供优质的服务。</p>
      <p>
        3、联系客服，进入通信网服中心，回复人工或人工客服或99，即可接入人工。
      </p>
    </div>
    <div class="bottom_btn">
      <van-button class="kefu" @click="kefu">联系在线客服</van-button>
      <van-button
        :style="{ background: flag ? '#888a8e' : '#1e6fff' }"
        :disabled="flag"
        @click="onbuyCard()"
        class="payMoney"
      >
        确认收货地址 需支付{{ shopInfo.one_price }}元
      </van-button>
    </div>
    <van-popup v-model="showKefu">
      <img style="width: 200px" src="~@/assets/images/hk/kefu.jpg" alt="" />
    </van-popup>
    <van-overlay :show="showLoading" @click="show = false" lock-scroll>
      <div class="wrapper" @click.stop>
        <div class="block">
          <van-loading size="32px" vertical><span style="color:#fff">加载中...</span></van-loading>
        </div>
      </div>
    </van-overlay>

    <!-- <div class="card3">
      <div class="header">
        <div class="name">商品详细</div>
        <div class="red"></div>
      </div>
      <div>
        <img
          src="https://staticimg.yuna8.com/tmp/uploads/20231115/170004211112.jpeg"
          alt=""
          style="width: 100%"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
var url = window.location.href; //获取当前url
var cs = url.split("?")[1]; //获取?之后的参数字符串
var cs_arr = cs.split("&"); //参数字符串分割为数组
var cs = {};
for (var i = 0; i < cs_arr.length; i++) {
  cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
}
console.log(cs, "cs");
import { areaList } from "@vant/area-data";
import isweixin from "@/untils/isweixin.js";
import {
  sendVerifyCode,
  getAddressByIccid,
  hkOrder,
  detail,
  buyCard,
  getOrderId,
  getBuyUrlScheme,
} from "@/untils/distubritionIndex.js";
var wx = require("weixin-js-sdk") || window["wx"];
export default {
  data() {
    return {
      picker: false,
      areaList,
      form: {
        name: "",
        phone: "",
        cardId: "",
        addressInfo: "",
        province: "",
        city: "",
        area: "",
        showCode: false,
      },
      codeText: "获取验证码",
      disabled: false,
      disabled2: false,
      areainfo: "",
      result: {},
      old_iccid: "",
      info: {},
      shopInfo: {}, //商品详情
      flag: false,
      showKefu: false,
      from: 1,
      times: null,
      payType: 1,
      wx: true, //微信环境
      showLoading: false,
    };
  },
  beforeDestroy() {
    clearTimeout(this.times);
  },
  mounted() {
    this.getInfo();
  },
  created() {
    if (isweixin()) {
      let local = window.location.href;
      if (!this.$route.query.openid) {
        window.location.href =
          "https://connect.yuna6.com/getBuyUrl?url=" +
          encodeURIComponent(local);
      } else {
        var search = window.location.search;
        var openid = this.getSearchString("openid", search);
        // this.p_openid = this.getSearchString('p_openid', search);//邀请人openid
        // this.sp = this.getSearchString('sp', search);
        this.from = this.getSearchString("from", search);

        window.sessionStorage.setItem("openid", openid);
      }
    } else {
      this.wx = false;
      // window.location.href = "http://localhost:8081/bigFlowPage?from=2&sp=8e296a067a37563370ded05f5a3bf3ec&p_openid=&invite_phone=17633596180"
      var search = window.location.search;
      // var openid = this.getSearchString('openid', search);
      // this.p_openid = this.getSearchString('p_openid', search);
      this.sp = this.getSearchString("sp", search);
      this.from = this.getSearchString("from", search);
      this.invite_phone = this.getSearchString("invite_phone", search);
      window.sessionStorage.setItem("openid", "");
    }
  },
  methods: {
    // 获取商品信息
    getDetail() {
      let data = {
        sp: this.info.sp,
      };
      detail({ data }).then((res) => {
        if (res.data.code == 1) {
          this.shopInfo = res.data.info;
        } else {
          this.$toast(res.data.msg);
        }
      });
    },
    //获取信息
    getInfo() {
      let data = {
        news_id: cs.i,
        from:1,//网页  统计短信是否已读
      };
      if(!isweixin()){
        this.showLoading = true
      }
      getAddressByIccid({ data }).then((res) => {
        console.log(res, "res");
        this.showLoading = false
        // 如果code=2 说明没有查到收货地址  code=0 是信息异常  1 正常
        if (res.data.code == 1) {
          this.info = res.data.data;
          // 获取当前时间
          const now = new Date();
          // 将当前时间转换为1970-01-01 08:00:00的时间戳
          const timestamp1970 = new Date(res.data.data.c_extime).getTime();
          // 计算时间差（毫秒）
          const timeDifference = now.getTime() - timestamp1970;

          // 比较时间大小
          if (timeDifference > 0) {
            this.isExtime = true;
          } else {
            this.isExtime = false;
          }
          this.info.c_extime = res.data.data.c_extime.slice(0, -8);
          this.form.name = res.data.data.name;
          this.form.phone = res.data.data.phone;
          this.form.province = res.data.data.sheng;
          this.form.city = res.data.data.shi;
          this.form.area = res.data.data.qu;
          this.form.addressInfo = res.data.data.address;
          this.form.showCode = false;
          this.old_iccid = res.data.data.old_iccid;
          this.result.Province = res.data.data.sheng;
          this.result.City = res.data.data.shi;
          this.result.District = res.data.data.qu;
          this.getAddress();
          // this.form.address= res.data.data.sheng + res.data.data.shi + res.data.data.qu
        } else if (res.data.code == 2) {
          this.info = res.data.data;
          this.info.c_extime = res.data.data.c_extime.slice(0, -8);
          this.old_iccid = res.data.data.old_iccid;
          this.form.province = "选择省";
          this.form.city = "/ 市";
          this.form.area = "/ 区";
          this.form.showCode = true;
          this.$toast(res.data.msg);
        } else {
          this.disabled2 = true;
          this.form.showCode = false;
          this.form.province = "选择省";
          this.form.city = "/ 市";
          this.form.area = "/ 区";
          this.$toast(res.data.msg);
        }
        this.getDetail();
      });
    },
    // 获取地址
    getAddress() {
      console.log(this.areaList.province_list, "省");
      this.provinceList = this.areaList.province_list;
      this.cityList = this.areaList.city_list;
      this.infoList = this.areaList.county_list;
      const obj = this.provinceList;
      const obj2 = this.cityList;
      const obj3 = this.infoList;
      for (let i in obj) {
        if (obj[i].includes(this.result.Province)) {
          this.form.province = obj[i];
        }
      }
      for (let i in obj2) {
        if (obj2[i].includes(this.result.City)) {
          this.areainfo = i;
          this.form.city = obj2[i];
        }
      }

      if (this.result.District != "") {
        for (let i in obj3) {
          if (obj3[i].includes(this.result.District)) {
            this.areainfo = i;
            this.form.area = obj3[i];
          }
        }
      } else {
        let areaArr = [];
        for (let i in obj3) {
          if (i.includes(this.areainfo.slice(0, 4))) {
            // console.log(i);
            // console.log(obj3[i]);
            areaArr.push({ val: i, name: obj3[i] });
          }
        }

        this.areainfo = areaArr[0].val;
        this.form.area = areaArr[0].name;
      }
    },
    //确认地址
    onbuyCard() {
      if (this.form.name == "") {
        this.$toast("请输入姓名");
        return;
      }
      if (this.form.phone == "") {
        this.$toast("请输入手机号");
        return;
      }
      // if(!(/^1[3456789]\d{9}$/.test(this.form.phone))){
      //   this.$toast('手机号格式不正确');
      //   return
      // }
      if (this.form.showCode && !this.form.cardId) {
        this.$toast("请输入验证码");
        return;
      }
      if (
        this.form.province == "选择省" ||
        this.form.city == "/ 市" ||
        this.form.area == "/ 区"
      ) {
        this.$toast("选择省市区");
        return;
      }
      if (this.form.addressInfo == "") {
        this.$toast("请输入详细地址");
        return;
      }
      this.flag = true;
      // 微信环境参数
      let data = {
        goods_id: this.shopInfo.id, //商品id
        name: this.form.name, //收件人
        price: this.shopInfo.one_price, //金额
        phone: this.form.phone, //手机
        invite_phone: "", //邀请人手机号，没有传空
        p_openid: "", //邀请人openid
        openid: window.sessionStorage.getItem("openid")
          ? window.sessionStorage.getItem("openid")
          : "",
        province: this.form.province,
        city: this.form.city,
        area: this.form.area,
        address: this.form.addressInfo, //详细地址
        real_identityid: "", //身份证
        code: this.form.cardId, //验证码
        platform: 1, //平台
        pay_type: 1, //支付方式 1 微信
        from: this.from, //来源,
        old_iccid: this.old_iccid,
      };
        this.showLoading = true
      if (this.times) {
        clearTimeout(this.times);
      }
      this.times = setTimeout(() => {
        if (isweixin()) {
          buyCard({ data }).then((res) => {
            this.showLoading = false
            this.disabled2 = false;
            this.flag = false;
            if (this.shopInfo.one_price == 0 && res.data.code == 1) {
              this.$toast(res.data.msg);
            } else if (this.shopInfo.one_price != 0 && res.data.code == 1) {
              if (isweixin()) {
                if (typeof WeixinJSBridge == "undefined") {
                  if (document.addEventListener) {
                    document.addEventListener(
                      "WeixinJSBridgeReady",
                      onBridgeReady,
                      false
                    );
                  } else if (document.attachEvent) {
                    document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                    document.attachEvent(
                      "onWeixinJSBridgeReady",
                      onBridgeReady
                    );
                  }
                } else {
                  this.onBridgeReady(res.data.info);
                }
              }
            } else if (res.data.code != 1) {
              this.$toast(res.data.msg);
            }
          });
        } else {
          console.log(this.payType, "方式");
          if (this.payType == 1) {
            delete data.openid;
            // 微信小程序支付
            data.platform = 4;
            data.pay_type = 1;
            console.log(data);
            getOrderId({ data }).then((res) => {
              this.showLoading = false
              if (res.data.code == 1) {
                let data = {
                  order_id: res.data.order_id,
                };
                // console.log(data);
                getBuyUrlScheme({ data }).then((res) => {
                  this.flag = false;
                  if (res.data.code == 1) {
                    setTimeout(() => {
                      window.location.href = res.data.info.urlscheme;
                    }, 1000);
                  } else {
                    this.$toast(res.data.msg);
                  }
                });
              } else {
                this.$toast(res.data.msg);
              }
              // getBuyUrlScheme
            });
          } else {
            // 支付宝支付
            data.pay_type = 5;
            buyCard({ data }).then((res) => {
              this.showLoading = false
              this.disabled2 = false;
              this.flag = false;
              if (res.data.code == 1) {
                console.log(res);
                this.$toast("即将跳转支付页面");
                setTimeout(() => {
                  window.location.href = res.data.info;
                }, 2000);
              } else {
              }
            });
          }
        }
      }, 1000);
      // let data = {
      //   name: this.form.name,
      //   phone: this.form.phone,
      //   province: this.form.province == "选择省" ? "" : this.form.province,
      //   city: this.form.city == "/ 市" ? "" : this.form.city,
      //   area: this.form.area == "/ 区" ? "" : this.form.area,
      //   code: this.form.cardId ? this.form.cardId : "",
      //   address: this.form.addressInfo,
      //   old_iccid: this.old_iccid,
      // };
      // hkOrder({ data }).then((res) => {
      //   this.$toast(res.data.msg);
      //   this.flag = false;
      // });
    },
    onBridgeReady(info) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: info.appId, //公众号名称，由商户传入
          timeStamp: info.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: info.nonceStr, //随机串
          package: info.package,
          signType: info.signType, //微信签名方式：
          paySign: info.paySign, //微信签名
        },
        function (res) {
          // 支付成功
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$toast("支付成功");
          }
          // 支付过程中用户取消
          if (res.err_msg == "get_brand_wcpay_request:cancel") {
            this.$toast("取消支付");
          }
          // 支付失败
          if (res.err_msg == "get_brand_wcpay_request:fail") {
            this.$toast("支付失败");
          }
          /**
           * 其它
           * 1、请检查预支付会话标识prepay_id是否已失效
           * 2、请求的appid与下单接口的appid是否一致
           * */
          if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {
          }
        }
      );
    },
    // 获取验证码
    getCode() {
      if (this.form.phone.trim().length != 11) {
        return this.$toast("请输入完整的手机号");
      }
      this.disabled = true;
      this.codeText = 60;
      let data = {
        phone: this.form.phone,
      };

      sendVerifyCode({ data }).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          this.$toast(res.data.msg);
        }
      });
      let timer = setInterval(() => {
        this.codeText--;
        if (this.codeText == 0) {
          this.codeText = "获取验证码";
          clearInterval(timer);
          this.disabled = false;
        }
      }, 1000);
    },
    // 确定
    confirm(value) {
      // console.log(value);
      this.form.province = value[0].name;
      this.form.city = value[1].name;
      this.form.area = value[2].name;
      this.form.address =
        this.form.province + " " + this.form.city + " " + this.form.area;
      this.picker = false;
    },
    cancel() {
      this.picker = false;
    },
    kefu() {
      this.showKefu = true;
    },
    selectPay(num) {
      this.payType = num;
    },
    getSearchString(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    },
  },
};
</script>

<style lang="less" scoped>
.hk {
  min-height: 100vh;
  background: #f8f9fa;
  overflow: hidden;
  padding-bottom: 100px;

  .idCard {
    display: flex;
    justify-content: space-between;
    margin: 15px;
    font-size: 14px;
    background: linear-gradient(141deg, #1088fe 34%, #19a2f0 103%);
    border-radius: 10px 10px 0 0;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;

    .idCard_left {
      div {
        margin: 15px 0;
        display: flex;
        // p:nth-child(1){
        //   text-align: right;
        // }
        // p{
        //   width: 80px;
        //   white-space: nowrap;
        // }
      }
    }

    .idCard_right {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }

  .card2 {
    text-align: left;
    line-height: 20px;
    border-radius: 0 0 10px 10px;
    margin: -20px 15px 0;
    padding: 10px 20px;
    box-shadow: 0px 4px 10px 0px rgba(5, 54, 102, 0.1);
    background-color: #fff;
    color: #1088fe;
    font-size: 14px;
  }

  .header_pic {
    img {
      width: 100%;
    }
  }

  .main_box {
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(5, 54, 102, 0.1);
    border-radius: 10px;
    margin: 15px;
    overflow: hidden;

    .main_tip {
      font-size: 14px;
      color: #3d3d3d;
      padding: 15px 0;
      margin: 0 15px;
      line-height: 18px;
    }

    .area {
      margin-bottom: 10px;
      display: flex;
      border-radius: 10px;
      background-color: #f7f5f5;
      height: 44px;
      // line-height: 44px;
      box-sizing: border-box;
      padding: 10px 16px;
      font-size: 14px;
      align-items: center;

      .area-a {
        -webkit-box-flex: 0;
        -webkit-flex: none;
        flex: none;
        box-sizing: border-box;
        width: 51px;
        margin-right: 0.32rem;
        color: #646566;
        text-align: left;
        word-wrap: break-word;
      }
    }

    .popBox {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      // background-color: #999999;
      height: 100%;

      .inp {
        margin-bottom: 10px;
      }

      .payType {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        background-color: #f7f5f5;
        border-radius: 10px;
        padding: 0 10px;
        box-sizing: border-box;

        .item {
          height: 54px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            img {
              width: 32px;
              height: 32px;
              margin-right: 10px;
            }
          }

          .right {
            img {
              width: 16px;
              height: 16px;
            }
          }
        }

        // .item:nth-child(1) {
        //     // border-bottom: 1px solid #e6e6e6;
        // }
      }

      /deep/ .van-cell-group {
        background-color: #f7f5f5;
        border-radius: 10px;

        .van-cell__title {
          width: 50px;
        }

        .van-cell {
          background-color: #f7f5f5;
          border-radius: 10px;
        }

        /deep/ .van-cell::after {
          border: none !important;
        }

        .van-hairline--top-bottom::after {
          border: none;
        }
      }

      .footbtn {
        // position: fixed;
        // bottom: 0;
        //margin-top: ;
        width: 94%;
        margin: 40px auto 10px;
        height: 50px;
        // background-color: #ed433d;
        box-sizing: border-box;
      }
    }
  }

  .card1 {
    background: #fff;
    margin: 15px;
    box-shadow: 0px 2px 10px 0px rgba(5, 54, 102, 0.1);
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    line-height: 24px;
    color: #666;

    div {
      text-align: center;
      padding: 10px 0;
      font-size: 18px;
      color: #000;
    }
  }

  .card3 {
    box-sizing: border-box;
    padding: 17px 10px;
    background-color: #fff;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      .red {
        width: 30px;
        height: 3px;
        background-color: red;
        border-radius: 3px;
      }
    }
  }

  .bottom_btn {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    padding: 10px 0 20px;
    font-weight: 500;
    background-color: #fff;
    box-shadow: 0px -1px 3px 0px rgba(138, 148, 149, 0.1608);
    border-radius: 10px 10px 0 0;

    .kefu {
      border-radius: 20px;
      background: #ffcd00;
      color: #3d3d3d;
      font-size: 16px;
    }

    .payMoney {
      border-radius: 20px;
      color: #fff;
      font-size: 16px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 120px;
    height: 120px;
  }
}
</style>>
